import { TRIP_STATUS } from '@/shared/common';

export const getters = {
  tripRequests(state) {
    return state.tripRequests;
  },
  tripRequestsById(state) {
    const byId = {};
    state.tripRequests.forEach((e) => (byId[e.id] = e));
    return byId;
  },
  currentTripRequestApprovalStatus(state) {
    if (!state.currentTripRequest) return null;

    const { approval, assignments, status } = state.currentTripRequest;
    const historyApproved = approval?.history.filter((h) => h.approved === 1);
    const isFullyApproved =
      historyApproved?.length === approval?.requiredApprovalLevels?.length && status !== TRIP_STATUS.DRAFT;
    const isPartiallyApproved = !!historyApproved?.length;
    const isAutoApproved =
      approval.approved && approval.requiredApprovalLevels.length === 0 && status !== TRIP_STATUS.DRAFT;
    const isAssigned = assignments?.length > 0;

    if (status === 0) return 'pending';
    if (isAutoApproved) return 'auto';
    if (isFullyApproved) return 'full';
    if (isPartiallyApproved) return 'partial';
    if (isAssigned) return 'assigned';

    return 'pending';
  },
  currentTripRequestPermissions(state) {
    if (!state.currentTripRequest) return null;

    return Object.keys(state.currentTripRequest.permissions);
  },
  isCurrentTripRequestApproved(state, getters) {
    const status = getters.currentTripRequestApprovalStatus;
    return ['auto', 'full', 'partial', 'assigned'].includes(status);
  },
  isFieldReadonlyAfterApproval(state, getters) {
    if (!state.currentTripRequest) return false;

    const editPermissions = [
      'canEdit',
      'canEditMidApproval',
      'canEditPreApproval',
      'canEditApproved',
      'canEditSiteAdmin',
    ];

    const isApproved = getters.isCurrentTripRequestApproved;

    if (!isApproved) return false;

    const permissions = getters.currentTripRequestPermissions;

    const hasEditPermission = editPermissions.some((permission) => permissions.includes(permission));

    if (isApproved && hasEditPermission) return false;

    return true;
  },
  isTripApprovedAndSubmittedByRequester(state, getters, rootState) {
    if (!state.currentTripRequest) return false;
    const isApproved = getters.isCurrentTripRequestApproved;
    if (!isApproved) return false;

    const me = rootState.user.me;
    if (isApproved && me.is.requester) return true;

    return false;
  },
  currentTripRequest(state) {
    return state.currentTripRequest;
  },
  currentTripLocation(state) {
    return state.currentTripLocation;
  },
  isCurrentTripFundingNoBudgetCode(state, getters, rootState, rootGetters) {
    return ({ fundingSourceIndex }) => {
      const currentTripRequest = state.currentTripRequest;

      if (!currentTripRequest) return false;

      const tripFundingSource = currentTripRequest.fundingSources[fundingSourceIndex];
      const fundingSourcesById = rootGetters['fundingSource/fundingSourcesById'];
      const fundingSource = fundingSourcesById[tripFundingSource.fundingSourceId];

      if (fundingSource.type !== 1) return false;

      if (!currentTripRequest) return false;

      const isTripNoBudgetCode = !tripFundingSource.budgetCodeId && !tripFundingSource.budgetCode;
      const isFundingSourceNoBudgetCode = !fundingSource.budgetCodeId;

      const hasNoBudgetCode = isTripNoBudgetCode || isFundingSourceNoBudgetCode;
      const isFundingSourceEditable = fundingSource.type === 4;

      if (hasNoBudgetCode && !isFundingSourceEditable) return true;

      return false;
    };
  },
  isCurrentTripRequester(state, getters, rootState, rootGetters) {
    const me = rootGetters['user/me'];

    return Number(state.currentTripRequest.createdBy) === me.id;
  },
  canApproveCurrentTrip(state, getters, rootState, rootGetters) {
    const me = rootGetters['user/me'];
    const isAdmin = rootGetters['user/isAdmin'];

    const tripApproval = state.currentTripRequest?.approval || null;

    if (!tripApproval) return false;

    const hasApprovers = tripApproval.requiredApprovalLevels?.length > 0;

    if (isAdmin) return true;
    if (getters.isCurrentTripRequester) return true;
    if (!hasApprovers && me.is.approver) return true;
    if (me.is.siteAdmin && state.currentTripRequest.approval.requiredApprovalLevels.length === 1) return true;

    const level = tripApproval.awaitingApproval?.id;
    const levelApprovers = [
      ...(tripApproval.awaitingApproval?.primaryAprovers || []),
      ...(tripApproval.awaitingApproval?.secondaryApprovers || []),
    ].reduce((acc, approver) => {
      if (approver?.approvalLevelId === level) {
        acc.push(approver?.userId);
      }
      return acc;
    }, []);

    return levelApprovers?.includes(me.id);
  },
  isLoading(state) {
    return state.isLoading;
  },

  isCurrentTripSubmittedOrDenied(state) {
    if (!state?.currentTripRequest?.status) return false;

    return [TRIP_STATUS.SUBMITTED, TRIP_STATUS.DENIED].includes(state.currentTripRequest.status);
  },

  allowTripReschedule(_, getters) {
    return getters.isCurrentTripSubmittedOrDenied;
  },

  allowTripDuplicate(state, getters, rootState) {
    if (!state.currentTripRequest) return false;

    return rootState.config.tripRequestConfig.display.duplicate && getters.isCurrentTripSubmittedOrDenied;
  },
};
