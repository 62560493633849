import { render, staticRenderFns } from "./SetupTable.vue?vue&type=template&id=1971d016&scoped=true"
import script from "./SetupTable.vue?vue&type=script&lang=js"
export * from "./SetupTable.vue?vue&type=script&lang=js"
import style0 from "./SetupTable.vue?vue&type=style&index=0&id=1971d016&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1971d016",
  null
  
)

export default component.exports