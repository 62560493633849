<template>
  <v-dialog v-model="dialog" width="unset" :persistent="persistent" no-click-animation>
    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-btn v-if="!persistent" icon @click="close" class="close-button">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="text-h6">{{ message }}</v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="choose(true)">{{ yesLabel }}</v-btn>
        <v-btn color="primary" @click="choose(false)">{{ noLabel }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmDialog',
  data() {
    return {
      dialog: false,
      message: '',
      yesLabel: 'YES',
      noLabel: 'NO',
      persistent: true,
      resolvePromise: undefined,
      rejectPromise: undefined,
    };
  },
  methods: {
    show(options) {
      this.message = typeof options === 'string' ? options : options.message;
      this.yesLabel = typeof options === 'string' ? 'YES' : options.yes;
      this.noLabel = typeof options === 'string' ? 'NO' : options.no;
      this.persistent =
        typeof options === 'object' && options !== null && options.persistent !== undefined
          ? options.persistent
          : this.persistent;
      this.dialog = true;
      return new Promise((res, rej) => {
        this.resolvePromise = res;
        this.rejectPromise = rej;
      });
    },
    destroyed() {
      this.dialog = false;
    },
    choose(value) {
      this.dialog = false;
      this.resolvePromise(value);
    },
    close() {
      this.dialog = false;
      this.rejectPromise();
    }
  },
};
</script>
<style scoped>
@media (max-width: 768px) {
  .v_dialog {
    max-width: 100% !important;
  }
}
.close-button {
  position: absolute;
  top: 0;
  right: 0;
  color: red !important;
}
</style>
