import invoiceApi from '@/apis/invoice';

const handleInvoiceStatus = async ({ state, dispatch, status: currentFsStatus, id: paymentId }) => {
  if (!state.details?.payment) return;

  const payments = state.details?.payment?.invoicePayments || [];
  const isAllApproved = payments.every((p) => p.status === 'approved');
  const hasDoNotBill = payments.some((p) => p.status === 'do_not_bill');
  let status = null;
  let setInvoiceStatus = false;

  if (hasDoNotBill && currentFsStatus === 'do_not_bill') {
    // Set all FS status to do_not_bill
    status = 'do_not_bill';
    setInvoiceStatus = true;
    payments.forEach((p) => (p.status = status));
  } else if (hasDoNotBill && currentFsStatus !== 'do_not_bill') {
    // Set all FS status to whatever the user selected
    status = currentFsStatus;
    setInvoiceStatus = true;
    payments.forEach((p) => (p.status = status));
  } else if (isAllApproved) {
    status = 'approved';
    setInvoiceStatus = true;
  } else if (currentFsStatus === 'pending') {
    // TODO: Handle if partially approved
    status = 'pending';
    setInvoiceStatus = true;
  }

  if (!setInvoiceStatus) return;

  await dispatch('saveInvoice', {
    invoiceId: state.details.payment.id,
    body: {
      isPaymentApproved: isAllApproved,
      status,
    },
  });
};

const _loadingMap = {
  staff_cost: 'staffCost',
  travel_cost: 'travelCost',
  additional_charge: 'additionalCharge',
  history: 'history',
  payment: 'payment',
};

export const actions = {
  async getInvoices({ state, commit }) {
    const options = {
      ...state.searchOptions,
      filter: state.filters,
    };

    commit('setLoading', { type: 'invoice', isLoading: true });
    const invoices = await invoiceApi.getInvoices({ options });

    commit('setInvoices', invoices);
    commit('setLoading', { type: 'invoice', isLoading: false });
  },
  async getInvoiceById({ state, commit }, invoiceId) {
    const options = {
      page: { pageSize: -1 },
      filter: { invoiceId },
    };

    commit('setLoading', { type: 'invoice', isLoading: true });
    const invoices = await invoiceApi.getInvoices({ options });

    if (!invoices?.data?.length) return;
    // the result should have the 1 result
    const result = invoices.data[0];

    commit('setInvoices', {
      ...state.invoices,
      data: [
        ...state.invoices.data.map((invoice) => {
          if (invoice.id === invoiceId) {
            return {
              ...invoice,
              ...result,
            };
          }

          return { ...invoice };
        }),
      ],
    });

    // update the selected invoice if it exists
    if (state.selectedInvoice && state.selectedInvoice.id === invoiceId) {
      commit('setSelectedInvoice', {
        ...state.selectedInvoice,
        ...result,
      });
    }

    commit('setLoading', { type: 'invoice', isLoading: false });
    return result;
  },
  async queryInvoices(store, options) {
    return await invoiceApi.getInvoices(options);
  },
  async getInvoiceDetails({ commit, dispatch }, { invoiceId, tab, manageLoading = true }) {
    try {
      if (manageLoading) commit('setLoading', { type: _loadingMap[tab], isLoading: true });

      const details = await invoiceApi.getInvoiceDetails({ invoiceId, tab });
      commit('setInvoiceDetails', { type: _loadingMap[tab] || 'invoice', details });
    } finally {
      if (manageLoading) commit('setLoading', { type: _loadingMap[tab], isLoading: false });
    }
  },

  async saveInvoice({ state, dispatch, commit }, { body, invoiceId, fullRefresh = false }) {
    try {
      commit('setLoading', { type: 'invoice', isLoading: true });
      const result = await invoiceApi.saveInvoice({ body, invoiceId });

      if (fullRefresh) {
        dispatch('getInvoices');
      } else {
        commit('setInvoices', {
          ...state.invoices,
          data: [
            ...state.invoices.data.map((invoice) => {
              if (invoice.id === invoiceId) {
                return {
                  ...invoice,
                  ...body,
                  ...result,
                };
              }

              return { ...invoice };
            }),
          ],
        });

        // update the selected invoice if it exists
        if (state.selectedInvoice && state.selectedInvoice.id === invoiceId) {
          commit('setSelectedInvoice', {
            ...state.selectedInvoice,
            ...body,
            ...result,
          });
        }
      }
    } finally {
      commit('setLoading', { type: 'invoice', isLoading: false });
    }
  },

  async saveInvoiceStaffCost({ dispatch, commit }, { body, invoiceId }) {
    try {
      commit('setLoading', { type: 'staffCost', isLoading: true });
      await invoiceApi.saveInvoiceStaffCost({ body, invoiceId });
      dispatch('getInvoiceDetails', { invoiceId, tab: 'staff_cost', manageLoading: false });
    } finally {
      commit('setLoading', { type: 'staffCost', isLoading: false });
    }
  },

  async deleteInvoiceStaffById({ commit, dispatch }, { invoiceId, staffCostId }) {
    try {
      commit('setLoading', { type: 'staffCost', isLoading: true });
      await invoiceApi.deleteInvoiceStaffById({ staffCostId, invoiceId });
      dispatch('getInvoiceDetails', { invoiceId, tab: 'staff_cost', manageLoading: false });
    } finally {
      commit('setLoading', { type: 'staffCost', isLoading: false });
    }
  },

  async getAttachments({ commit }, attachmentID) {
    const attachments = await invoiceApi.getAttachmentsOfInvoice(attachmentID);
    commit('getAttachments', attachments);
  },

  async removeAttachment({ commit }, attachment) {
    commit('removeAttachment', attachment);
  },

  async saveInvoiceTravelCost({ dispatch, commit }, { body, invoiceId }) {
    try {
      commit('setLoading', { type: 'travelCost', isLoading: true });
      await invoiceApi.saveInvoiceTravelCost({ body, invoiceId });
      dispatch('getInvoiceDetails', { invoiceId, tab: 'travel_cost', manageLoading: false });
    } finally {
      commit('setLoading', { type: 'travelCost', isLoading: false });
    }
  },

  async saveInvoiceAdditionalCharge({ dispatch, commit }, { body, invoiceId }) {
    try {
      commit('setLoading', { type: 'additionalCharge', isLoading: true });
      await invoiceApi.saveInvoiceAdditionalCharge({ body, invoiceId });
      dispatch('getInvoiceDetails', { invoiceId, tab: 'additional_charge', manageLoading: false });
    } finally {
      commit('setLoading', { type: 'additionalCharge', isLoading: false });
    }
  },

  async saveInvoicePayment({ dispatch, commit, state }, { body, invoiceId }) {
    try {
      commit('setLoading', { type: 'payment', isLoading: true });
      await invoiceApi.saveInvoicePayment({ body, invoiceId });
      await dispatch('getInvoiceDetails', { invoiceId, tab: 'payment', manageLoading: false });

      await handleInvoiceStatus({ state, dispatch, ...body });
    } finally {
      commit('setLoading', { type: 'payment', isLoading: false });
    }
  },

  async deleteInvoicePaymentById({ dispatch, commit }, { invoiceId, id }) {
    try {
      commit('setLoading', { type: 'payment', isLoading: true });
      await invoiceApi.deleteInvoicePaymentById({
        invoiceId,
        id,
      });
      dispatch('getInvoiceDetails', { invoiceId, tab: 'payment', manageLoading: false });
    } finally {
      commit('setLoading', { type: 'payment', isLoading: false });
    }
  },

  async deleteInvoiceCommentById({ dispatch }, { invoiceId, commentId }) {
    await invoiceApi.deleteInvoiceCommentById({
      invoiceId,
      commentId,
    });
    dispatch('getInvoiceDetails', { invoiceId, tab: 'history', manageLoading: false });
  },

  async addInvoiceComment({ dispatch }, { invoiceId, commentForm }) {
    await invoiceApi.addInvoiceComment({ invoiceId, body: commentForm });
    dispatch('getInvoiceDetails', { invoiceId, tab: 'history', manageLoading: false });
  },

  async getSetupTableTravelCost(_, params) {
    return await invoiceApi.getSetupTableTravelCost(params);
  },

  async deleteInvoiceTravelById({ commit, dispatch }, { invoiceId, id }) {
    try {
      commit('setLoading', { type: 'travelCost', isLoading: true });
      await invoiceApi.deleteInvoiceTravelById({ travelCostId: id, invoiceId });
      dispatch('getInvoiceDetails', { invoiceId, tab: 'travel_cost', manageLoading: false });
    } finally {
      commit('setLoading', { type: 'travelCost', isLoading: false });
    }
  },
  async printInvoices(_, params) {
    await invoiceApi.printInvoices(params);
  },

  async showInvoice({ dispatch, commit, rootGetters }, item = null) {
    if (!item) return;

    const { tripRequest } = await dispatch('tripRequest/getTripRequest', item.tripRequestId, { root: true });
    const tripLocation = rootGetters['location/locationsById']?.[tripRequest.locationId];

    await dispatch('tripRequest/setCurrentTripDetails', { trip: tripRequest, location: tripLocation }, { root: true });

    commit('setInvoiceFilterDisabled', true);
    dispatch('getAttachments', item.id);
    commit('setSelectedInvoice', item);
  },

  async hideInvoice({ dispatch, commit }) {
    commit('setInvoiceFilterDisabled', false);
    commit('setSelectedInvoice', null);
    await dispatch('getInvoices');
  },
};
